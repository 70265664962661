import React from "react"
import PropTypes from "prop-types"

import LayoutMetaData from "./LayoutMetaData"
import Navbar from "../components/layout/navbar"
import Footer from "../components/layout/footer"

import "../assets/css/bootstrap.min.css"
import "../assets/css/bootstrap-rtl.css"
import "../assets/css/paper-kit.css"
import "../assets/font-awesome-4.3.0/css/font-awesome.min.css"
import "../assets/css/style.css"
import "../assets/css/custom.css"


const DefaultLayout = ({ children }) => (
  <LayoutMetaData>
    <Navbar />
    {children}
    <Footer />
  </LayoutMetaData>
)

DefaultLayout.propTypes = {
  children: PropTypes.any.isRequired,
}

export default DefaultLayout
