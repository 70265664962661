import React from "react"
import { connect } from "react-redux"
import { Row } from "reactstrap"
import { injectIntl, FormattedMessage, Link } from "gatsby-plugin-intl"

import LanguageSelector from "./languageSelector"
import logo from "../../images/logo/logo.png"
import * as actions from "../../store/actions"


const Footer = ({ intl, regions, selectedRegionCode, setSelectedRegion, setActiveNavbarItem }) => {

  const linksItems = ["airQuality", "maps", "achievements", "wildLifeAgreement", "links"]
  const currentYear = new Date()
  return (
    <div className="footer">
      <div className="footer-links-holder">
        <div className="container" style={{ textAlign: intl.locale === "ar" ? "right" : "left" }}>
          <div className="row">
            <div className="col-sm-4">
              <div className="footer-logo-wrapper" onClick={() => setActiveNavbarItem("home")}>
                <Link to={"/?country=" + selectedRegionCode} style={{ color: "white" }}>
                  <img src={logo} alt="logo" data-at2x={logo} />
                  <h5 className="mb-0"><FormattedMessage id="logo_title_1" /></h5>
                  <h5><FormattedMessage id="logo_title_2" /></h5>
                </Link>
                <p><FormattedMessage id="brief" /></p>
              </div>
              <div>
                <ul className="inline-ul">
                  <LanguageSelector color="white" />
                </ul>
              </div>
            </div>
            <div className="col-sm-4">
              <h4>
                <FormattedMessage id="main_links" />
              </h4>

              <p onClick={() => setActiveNavbarItem("home")}>
                <Link to={"/?country=" + selectedRegionCode}>
                  <FormattedMessage id="home" />
                </Link>
              </p>

              {linksItems.map(link => (
                <p key={link} onClick={() => setActiveNavbarItem(link)}>
                  <Link to={"/" + link + "/?country=" + selectedRegionCode}>
                    <FormattedMessage id={link} />
                  </Link>
                </p>))}
            </div>
            <div className="col-sm-4">
              <h4>
                <FormattedMessage id="GCC_countries" />
              </h4>
              {regions.map((region, i) => (
                <p key={i}>
                  <Link to={"/?country=" + region.code} onClick={() => setSelectedRegion(region.code)}>
                    {region.name}
                  </Link>
                </p>
              ))}
            </div>
          

          </div>
        </div>
      </div>
      <div className="footer-copyrights">
        <div className="container">
          <Row>
            <div className="col-md-6">
              <a href="/copyrights" style={{ float: "right" }}>
                &copy; <FormattedMessage id="copyright" /> {currentYear.getFullYear()}
              </a>
            </div>
            <div className="col-md-6">
              <a href="/usagePolicy">
                <FormattedMessage id="terms and conditions" />
              </a>
              {" - "}
              <a href="/privacyPolicy">
                <FormattedMessage id="privacy policy" />
              </a>
            </div>
          </Row>
        </div>
      </div>
    </div >
  )
}

const mapStateToProps = state => {
  return {
    regions: state.regions.regions,
    selectedRegionCode: state.regions.selectedRegion && state.regions.selectedRegion.code,

  }
}

const mapDispatchToProps = dispatch => {
  return {
    setSelectedRegion: regionCode => dispatch(actions.setSelectedRegion(regionCode)),
    setActiveNavbarItem: navbarItem => dispatch(actions.setActiveNavbarItem(navbarItem)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Footer))
