import React from "react"
import { connect } from "react-redux"
import { IntlContextConsumer, changeLocale } from "gatsby-plugin-intl"

import store from "../../store/store"
import * as actions from "../../store/actions";
import { applyArabicCss, applyEnglishCss } from "../../shared/utility"


const languageName = {
  en: "ENG",
  ar: "العربية",
}

const Language = ({ color, selectedRegion }) => {

  var isMobile = null

  React.useEffect(() => {
    isMobile = window.innerWidth < 991
  }, [])

  return (
    <div className="dropdown-title">
      <IntlContextConsumer>
        {({ languages, language: currentLocale }) =>
          languages.map(language => (
            <div
              key={language}
              onClick={() => {

                changeLocale(language)
                if (language === 'ar') {
                  applyArabicCss()
                  window.localStorage.setItem('user-pref-lang', 'ar')
                } else {
                  applyEnglishCss()
                  window.localStorage.setItem('user-pref-lang', 'en')
                }
                store.dispatch(actions.fetchRegions(language, selectedRegion.code));
              }}
              style={{
                
                fontWeight: `bold`,
                cursor: `pointer`,
                display: currentLocale === language ? `none` : `unset`,
              }}
            >
              {languageName[language]}
            </div>
          ))
        }
      </IntlContextConsumer>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    selectedRegion: state.regions.selectedRegion,
  }
}

export default connect(mapStateToProps)(Language)
