import React, { Component } from "react"
import { connect } from "react-redux"
import { navigate, injectIntl } from "gatsby-plugin-intl"

import * as actions from "../../store/actions"


class SearchBox extends Component {

    state = {
        query: ""
    }

    submitForm = (e) => {
        e.preventDefault();
        this.props.setActiveNavbarItem("search");
        navigate("/search/?query=" + this.state.query)
        this.setState({ query: "" })
    }

    handleChange = (e) => {
        e.preventDefault();
        this.setState({ query: e.target.value })
    }

    render() {
        return (
            <li className="nav-item">
                <form>
                    <div className="d-flex justify-content-right  h-100">
                        <div className="searchbar">
                            <input className="search_input" type="text" name="search"
                                value={this.state.query}
                                placeholder={this.props.intl.formatMessage({ id: "search_placeholder" })}
                                onChange={this.handleChange} />
                            <button onClick={this.submitForm} className="search_icon" disabled={this.state.query === ""}>
                                <i className="fa fa-search" style={{ fontSize: "20px" }}></i>
                            </button>
                        </div>
                    </div>
                </form>
            </li>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setActiveNavbarItem: navbarItem => dispatch(actions.setActiveNavbarItem(navbarItem)),
    }
}

export default connect(null, mapDispatchToProps)(injectIntl(SearchBox));