import React from "react"
import { connect } from "react-redux"
import classnames from "classnames"
import { Collapse, Navbar, NavItem, Nav, } from "reactstrap"
import { FormattedMessage, injectIntl, Link, navigate } from "gatsby-plugin-intl"

import LanguageSelector from "./languageSelector"
import BackDrop from "./backdrop"
import CountrySelector from "./countrySelector"
import logo from "../../images/logo/logo.png"
import * as actions from "../../store/actions"
import SearchBox from "../search/searchBox"


function MainNavbar({ intl, selectedRegionCode, activeNavbarItem, setActiveNavbarItem }) {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent")
  const [languageColor, setLanguageColor] = React.useState("white")
  const [navbarCollapse, setNavbarCollapse] = React.useState(false)

  const toggleNavbarCollapse = () => {
    setNavbarCollapse(!navbarCollapse)
    document.documentElement.classList.toggle("nav-open")
  }

  const closeNavbarCollapse = () => {
    setNavbarCollapse(false)
    document.documentElement.classList.remove("nav-open")
  }

  const handleNavbarItemClick = navbarItem => {
    closeNavbarCollapse()
    setActiveNavbarItem(navbarItem)
    navigate("/" + navbarItem + "/?country=" + selectedRegionCode)
  }

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (document.documentElement.scrollTop > 79 || document.body.scrollTop > 79) {
        if (activeNavbarItem === "home") {
          setNavbarColor("black")
          setLanguageColor("white")
        } else {
          setNavbarColor("white")
          setLanguageColor("black")
        }
      } else if (document.documentElement.scrollTop < 80 || document.body.scrollTop < 80) {
        if (activeNavbarItem === "home") {
          setNavbarColor("navbar-transparent")
          setLanguageColor("white")
        } else {
          setNavbarColor("white")
          setLanguageColor("black")
        }
      }
    }
    window.addEventListener("scroll", updateNavbarColor)
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor)
    }
  })

  React.useEffect(() => {
    if (activeNavbarItem !== "home") {
      setNavbarColor("white")
      setLanguageColor("black")
    }
  }, [activeNavbarItem]);

  const navBarItems = ["airQuality", "maps", "achievements", "wildLifeAgreement", "links"]


  const activeNavItemStyle = {
    borderBottom: " solid 2px #2ac3ef",
  }
  const activeNavLinkStyle = {
    color: "#2ac3ef"
  }

  return (
    <Navbar
      className={classnames("fixed-top", navbarColor)}
      expand="lg">
      <div className="container-fluid">
        <div className="navbar-translate">
          <button
            aria-expanded={navbarCollapse}
            className={classnames("navbar-toggler", { toggled: navbarCollapse })}
            onClick={toggleNavbarCollapse}>
            <span className="navbar-toggler-bar bar1" />
            <span className="navbar-toggler-bar bar2" />
            <span className="navbar-toggler-bar bar3" />
          </button>
        </div>
        <Collapse navbar className="justify-content-start" isOpen={navbarCollapse}>
          <Nav navbar style={{ textAlign: intl.locale === "ar" ? "right" : "left" }}>
            <NavItem className="justify-content-center" onClick={() => setActiveNavbarItem("home")}>
              <Link to={"/?country=" + selectedRegionCode} style={{ color: "black" }}>
                <div className="logo-wrapper">
                  <img className="logo" src={logo} alt="logo" data-at2x={logo} />
                  <h5 className="mb-0 logo-text">
                    <FormattedMessage id="logo_title_1" />
                    <br />
                    <FormattedMessage id="logo_title_2" />
                  </h5>
                </div>
              </Link>
            </NavItem>

            {navBarItems.map(navItem =>
              <NavItem key={navItem} onClick={() => handleNavbarItemClick(navItem)}
                style={activeNavbarItem === navItem && !navbarCollapse ? activeNavItemStyle : null}>
                <div className="nav-link" style={activeNavbarItem === navItem && !navbarCollapse ? activeNavLinkStyle : null}>
                  <FormattedMessage id={navItem} />
                </div>
              </NavItem>)}

            <SearchBox />

            <CountrySelector />

            <NavItem style={{ marginTop: "23px" }}>
              <LanguageSelector color={languageColor} />
            </NavItem>

          </Nav>
        </Collapse>

        <BackDrop show={navbarCollapse} clicked={closeNavbarCollapse} />
      </div>
    </Navbar>
  )
}

const mapStateToProps = state => {
  return {
    selectedRegionCode: state.regions.selectedRegion && state.regions.selectedRegion.code,
    activeNavbarItem: state.ui.activeNavbarItem
  }
}
const mapDispatchToProps = dispatch => {
  return {
    setActiveNavbarItem: navbarItem => dispatch(actions.setActiveNavbarItem(navbarItem)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(MainNavbar))
