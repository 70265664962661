import React, { useState } from "react"
import { connect } from "react-redux"
import { injectIntl, FormattedMessage } from "gatsby-plugin-intl"
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  NavItem,
  Dropdown,
} from "reactstrap"


import * as actions from "../../store/actions"
import './style.css'


const RegionsOptions = (props) => {
  const {
    selectedRegion,
    regions,
    handleSelectRegion,
    intl,
  } = props

  return regions.map((region, i) => {
    if (selectedRegion) {
      return (
        <DropdownItem
          key={i}
          className={region.code === selectedRegion.code ? "selected country-dropdown-inner" : "country-dropdown-inner"}
          onClick={e => handleSelectRegion(e, region)}>
          <div style={{ direction: intl.locale === "ar" ? "rtl" : "ltr" }}>
            <img src={region.image} alt="country flag" />
            <p>{region.name}</p>
          </div>
        </DropdownItem>
      )
    } else {
      return null
    }
  })
}

const CountrySelector = ({ intl, regions, selectedRegion, setSelectedRegion }) => {

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);

  const handleSelectRegion = (e, region) => {
    e.preventDefault()
    setSelectedRegion(region.code)
  }

  return (
    <NavItem className={intl.locale === 'ar' ? 'nav-country-desktop-view-arabic' : 'nav-country-desktop-view-english'}>
      <Dropdown isOpen={dropdownOpen} toggle={toggle} style={{ direction: intl.locale === 'ar' ? "rtl" : "ltr" }}>
        <DropdownToggle
          caret
          aria-expanded={false}
          aria-haspopup={true}
          color="neutral"
          data-toggle="dropdown"
          id="dropdownMenuButton"
          onClick={e => e.preventDefault()}
          role="button"
          className="country-dropdown">

          {selectedRegion &&
            <div className="country-dropdown-inner">
              <img src={selectedRegion.image} alt="country flag" />
              <div className="dropdown-title">{selectedRegion.name}</div>
            </div>}

        </DropdownToggle>
        <DropdownMenu
          aria-labelledby="dropdownMenuButton"
          className="dropdown-info countries-dropdown-menu">

          {regions &&
            <RegionsOptions selectedRegion={selectedRegion} regions={regions}
              handleSelectRegion={handleSelectRegion} intl={intl} />}

        </DropdownMenu>
      </Dropdown>

      <div className="nav-link nav-country-responsive-view" >
        <div className="country-name-nav"><FormattedMessage id="country" /></div>
        {regions &&
          <RegionsOptions selectedRegion={selectedRegion} regions={regions}
            handleSelectRegion={handleSelectRegion} intl={intl} />}
      </div>
    </NavItem>
  )
}

const mapStateToProps = state => {
  return {
    regions: state.regions.regions,
    selectedRegion: state.regions.selectedRegion,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setSelectedRegion: regionCode => dispatch(actions.setSelectedRegion(regionCode)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(CountrySelector))
